<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Compound B has a molecular formula of
        <chemical-latex content="C7H16O" />
        . What is the Unsaturation Number (UN) or Double-Bond Equivalent (DBE)?
      </p>
      <calculation-input
        v-model="inputs.input1"
        class="mb-5"
        prepend-text="$\text{UN/DBE}:$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        Based on the UN/DBE you calculated, could there be a double bond in compound B?
      </p>
      <v-radio-group v-model="inputs.input2" class="ml-6 mb-5" :disabled="!allowEditing">
        <v-radio v-for="option in options" :key="option.text" :value="option.value" class="my-1">
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-2">Based on the UN/DBE you calculated, could there be a ring in compound B?</p>
      <v-radio-group v-model="inputs.input3" class="ml-6 mb-5" :disabled="!allowEditing">
        <v-radio v-for="option in options" :key="option.text" :value="option.value" class="my-1">
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '../StembleLatex.vue';
import CalculationInput from '../inputs/CalculationInput';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'ChemUCI51LBA2M2Q1',
  components: {
    ChemicalLatex,
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
      },
      options: [
        {text: 'yes', value: 'yes'},
        {text: 'no', value: 'no'},
      ],
    };
  },
};
</script>
